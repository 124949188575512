:root {
  --white: #fff;
  --white-rgb: 255, 255, 255;

  --black: #000;
  --black-rgb: 0,0,0;

  --gray: #333334;
  --gray-rgb: 51, 51, 52;

  --blue: #00689d;
  --blue-rgb: 0, 104, 157;
}

body {
  --link-color-rgb: var(--white-rgb);
  --link-color: rgba(var(--link-color-rgb), 1);

  font-size: 1.125rem;
  font-weight: 500;
  color: var(--white);
  background-color: var(--gray);
}

p {
  line-height: 1.5;
  margin-top: 0;
}

.section {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.link {
  color: var(--link-color);
  font-weight: 600;
  border: 0.125rem solid rgba(var(--link-color-rgb), 0.5);
  padding: 1rem 2rem;
  border-radius: 10rem;
  text-decoration: none;
  transition: background-color 0.1s linear;
}
.link:hover {
  background-color: rgba(var(--link-color-rgb), 0.1);
}

.d--if {
  display: inline-flex;
}

.ws--nw {
  white-space: nowrap;
}
